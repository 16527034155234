import React, { useEffect, useState } from "react";
import { getCourseBycourseRefId, getCourseById } from "../../Services/Function";
import { CourseName, Fees, University } from "./styled";
import { ButtonComponent } from "../ButtonComponent";
import { NotAddedText } from "../DocumentPage/style";
import { StartApplication } from "../StartApplication";

type Props = {
  student?: any;
  reoadStudents?: any;
};

export const Shortlists = (props: Props) => {
  const [courses, setcourses] = useState([]);
  const [modalShow, setmodalShow] = useState(false);
  const [selectedCourse, setselectedCourse] = useState("");
  const [courseDetail, setcourseDetail] = useState("");

  const { student, reoadStudents } = props;

  const reloadCourses = async () => {
    let courses: any = [];
    for (
      let index = 0;
      index < props.student.shortlistedCourses.length;
      index++
    ) {
      const element = props.student.shortlistedCourses[index];
      const course = await getCourseById(element);
      courses.push(course.data);
    }
    setcourses(courses);
  };
  useEffect(() => {
    async function fetchData() {
      let courses: any = [];
      for (
        let index = 0;
        index < props.student.shortlistedCourses.length;
        index++
      ) {
        const element = props.student.shortlistedCourses[index];
        const course = await getCourseById(element);
        courses.push(course.data);
      }
      setcourses(courses);
    }
    fetchData();
  }, [props.student.shortlistedCourses]);

  return (
    <div style={{ marginTop: 40 }}>
      {!courses.length ? (
        <div className="d-flex justify-content-center align-items-center flex-column h-100">
          <div
            style={{
              backgroundImage: `url(${"https://storage.googleapis.com/uapply/no-document.png"})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: 230,
              height: 205,
              borderRadius: 8,
            }}
          />
          <NotAddedText>No shortlisted courses</NotAddedText>
        </div>
      ) : (
        <>
          {courses.map((course: any) => (
            <div style={{ padding: 24, borderBottom: "1px solid #CCD7E6" }}>
              <div className="d-flex justify-content-between">
                {" "}
                <div>
                  <CourseName>{course.name}</CourseName>
                </div>
                <div>
                  <Fees>
                    <b> GBP {course.approxAnnualFee}</b>{" "}
                    <span
                      style={{
                        color: "#42526E",
                        fontSize: 12,
                        fontWeight: "normal",
                      }}
                    >
                      - first year
                    </span>
                  </Fees>
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <University>{course.institution}, UK</University>
                <div>
                  <University style={{ fontWeight: 400 }}>
                    {course.courseLevel} • {course.attendanceTypes[0]} •{" "}
                    {course.courseDurationValues[0] === 360
                      ? "1 Year"
                      : course?.courseDurationValues[0] === 720
                        ? "2 Years"
                        : course?.courseDurationValues[0] === 1080
                          ? "3 Years"
                          : "4 Years"}
                  </University>
                </div>
              </div>
              <div
                style={{ marginTop: 25 }}
                className="d-flex flex-row justify-content-between"
              >
                <div>
                  <University style={{ color: "#0052CC", fontWeight: 400 }}>
                    View More Details
                  </University>
                </div>
                <div className="d-flex flex-row ">
                  <ButtonComponent
                    label={"SHORTLISTED"}
                    loading={false}
                    style={{
                      width: "max-content",
                      borderRadius: 20,
                      marginRight: 12,
                      padding: "8px",
                    }}
                    btnType={"light"}
                  />
                  <ButtonComponent
                    style={{ width: "max-content", borderRadius: 20 }}
                    label={"APPLY"}
                    loading={false}
                    onClick={async () => {
                      setselectedCourse(course);
                      const courseDetail = await getCourseBycourseRefId(
                        course.courseRefId
                      );
                      setcourseDetail(courseDetail[0]);
                      setmodalShow(true);
                    }}
                    btnType={"dark"}
                  />
                </div>
              </div>
            </div>
          ))}
        </>
      )}
      {modalShow ? (
        <StartApplication
          show={modalShow}
          courseDetail={courseDetail}
          selectedStudent={student}
          handleClose={() => setmodalShow(false)}
          selectedCourse={selectedCourse}
          reoadStudents={reoadStudents}
          reloadCourses={reloadCourses}
        />
      ) : null}
    </div>
  );
};
