import * as React from "react";
import { Modal } from "react-bootstrap";
import { ButtonComponent } from "../ButtonComponent";

interface IDeleteModalProps {
  onHide: any;
  onClick: any;
  show: boolean;
  selectedStudent?: any;
}

export const DeleteModal: React.FunctionComponent<IDeleteModalProps> = (
  props
) => {
  return (
    <Modal
      id="myModal "
      className="delete-modal"
      show={props.show}
      onHide={() => props.onHide()}
      size="sm"
      centered
    >
      <Modal.Header>
        <Modal.Title
          style={{ fontSize: 18 }}
          id="contained-modal-title-vcenter"
        >
          {props.selectedStudent
            ? `  Remove ${props.selectedStudent.firstName} ${props.selectedStudent.lastName}`
            : "Delete"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-row">
          <ButtonComponent
            btnType="dark"
            loading={false}
            label="Delete"
            onClick={() => props.onClick()}
          />
          <ButtonComponent
            btnType="light"
            loading={false}
            label="Close"
            onClick={() => props.onHide()}
            style={{ marginLeft: 10 }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
