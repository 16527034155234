import React, { useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { ButtonComponent } from "../ButtonComponent";
import { updateStudent } from "../../Services/Function";

type Props = {
  onClick: any;
  onHide: any;
  show: boolean;
  selectedUser: any;
  student: any;
  reloadStudents: any;
  id: any;
};

export const AssignRoleModal = (props: Props) => {
  const [roles, setRoles] = useState<string[]>([]);

  const toggleRole = (role: string) => {
    setRoles((prevRoles) =>
      prevRoles.includes(role)
        ? prevRoles.filter((r) => r !== role)
        : [...prevRoles, role]
    );
  };

  const assignRoles = async () => {
    const promises = roles.map(async (role) => {
      let existingAssignment = props.student.assignedTo.find(
        (item: any) => item.user === props.selectedUser.id
      );

      if (existingAssignment) {
        // Update existing assignment with the new role
        const updatedRoles = [...new Set([...existingAssignment.role, role])];
        const updatedAssign = props.student.assignedTo.map((user: any) =>
          user.user === props.selectedUser.id
            ? { ...user, role: updatedRoles }
            : user
        );

        await updateStudent(props.id, {
          assignedTo: updatedAssign.map((user: any) =>
            user.user === props.selectedUser.id
              ? { ...user, role: role } // Update with the individual role string
              : user
          ),
        });
      } else {
        // Create a new assignment if it doesn't exist
        const data = roles.map((z: any) => {
          return {
            user: props.selectedUser.id,
            role: z, // Assign the current role as a string
          };
        });

        await updateStudent(props.id, {
          assignedTo: [...props.student.assignedTo, ...data],
        });
      }
    });

    await Promise.all(promises); // Wait for all roles to be assigned
    await props.reloadStudents();
    props.onHide();
  };

  return (
    <div>
      <Modal
        id="myModal"
        show={props.show}
        onHide={() => props.onHide()}
        size="sm"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            style={{ fontSize: 18 }}
            id="contained-modal-title-vcenter"
          >
            Assign Roles
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Dropdown style={{ marginBottom: 20 }}>
            <Dropdown.Toggle style={{ textTransform: "capitalize" }}>
              {roles.length > 0 ? roles.join(", ") : "Select Roles"}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => toggleRole("sales")}
                active={roles.includes("sales")}
                eventKey="1"
              >
                Sales
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => toggleRole("account manager")}
                active={roles.includes("account manager")}
                eventKey="2"
              >
                Account Manager
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => toggleRole("operations")}
                active={roles.includes("operations")}
                eventKey="3"
              >
                Operations
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <div className="d-flex flex-row">
            <ButtonComponent
              btnType="dark"
              loading={false}
              label="Assign"
              onClick={assignRoles}
            />
            <ButtonComponent
              btnType="light"
              loading={false}
              label="Close"
              onClick={() => props.onHide()}
              style={{ marginLeft: 10 }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
