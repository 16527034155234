import * as React from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { ButtonComponent } from "./ButtonComponent";
import { updateStudent } from "../Services/Function";

type Props = {
  onHide: any;
  onClick: any;
  show: boolean;
  selectedStudent?: any;
  reloadStudents?: any;
  student: any;
};

export const MoveToLost = (props: Props) => {
  const [selectedReason, setselectedReason] = React.useState("");

  return (
    <Modal
      id="myModal"
      show={props.show}
      onHide={() => props.onHide()}
      size="sm"
      centered
    >
      <Modal.Header>
        <Modal.Title
          style={{ fontSize: 18 }}
          id="contained-modal-title-vcenter"
        >
          {"Move to Lost"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Dropdown>
          <Dropdown.Toggle
            className="dd-custom-toggle"
            id="dropdown-custom-components"
          >
            {selectedReason
              ? selectedReason
              : props.student.lostReason
                ? props.student.lostReason
                : "Select"}
          </Dropdown.Toggle>
          <Dropdown.Menu className="dd-custom-menu">
            {[
              "Non Partner University",
              "Changed Countries",
              "Change of Agent",
              "Accepts in Medicine",
            ].map((a: any) => {
              return (
                <Dropdown.Item
                  key={a.id}
                  className="dd-custom-items"
                  onClick={async () => {
                    setselectedReason(a);
                  }}
                >
                  {a}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
        <div className="d-flex flex-row">
          <ButtonComponent
            btnType="dark"
            loading={false}
            label="Submit"
            onClick={async () => {
              await updateStudent(props.student.id, {
                lostReason: selectedReason,
                lost: true,
                stage: "Lost",
              });
              props.reloadStudents();
              props.onHide();
            }}
          />
          <ButtonComponent
            btnType="light"
            loading={false}
            label="Close"
            onClick={() => props.onHide()}
            style={{ marginLeft: 10 }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
