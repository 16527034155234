import { Form, Formik, FormikHelpers } from "formik";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FormInput } from "../FormInput";
import { ButtonComponent } from "../ButtonComponent";
import { SubText } from "./styled";
import { AddDocBtn } from "../DocumentPage/style";
import { updateDocument } from "../../Services/Function";
import { DateComponent } from "../DateComponent";
import ReactDatePicker from "react-datepicker";
import { Label } from "../FormInput/styled";
import moment from "moment";

type Props = {
  show: boolean;
  handleClose: any;
  document: any;
  setuploadModal: any;
  reloadDocuments: any;
};

export const EditDocModal = (props: Props) => {
  const [loading, setloading] = useState(false);
  const [attachemnts, setattachemnts] = useState(props.document.blobInfo);
  const [leavingDate, setleavingDate] = React.useState<any>(
    new Date(props.document.tag.endDate) || ""
  );
  const [joiningDate, setjoiningDate] = React.useState<any>(
    props.document.tag.startDate === "Invalid date"
      ? null
      : new Date(props.document.tag.startDate)
  );
  const [issueDate, setissueDate] = React.useState<any>(
    props.document.tag.issueDate === "Invalid date"
      ? null
      : new Date(props.document.tag.issueDate)
  );
  const [validDate, setvalidDate] = React.useState<any>(
    props.document.tag.validDate === "Invalid date"
      ? null
      : new Date(props.document.tag.validDate)
  );
  const startYear = new Date(props.document.tag.startYear).getFullYear();
  const startMonth = new Date(props.document.tag.startMonth).getMonth();
  const date = startYear ? new Date(startYear, startMonth) : "";
  const [startDate, setstartDate] = React.useState<any>(date);

  const endYear = new Date(props.document.tag.endYear).getFullYear();
  const endMonth = new Date(props.document.tag.endMonth).getMonth();
  const date1 = endMonth && endYear ? new Date(endYear, endMonth - 1, 1) : "";
  const [endDate, setendDate] = React.useState<any>(date1);

  return (
    <div>
      <Modal
        contentClassName="doc-modal"
        show={props.show}
        onHide={props.handleClose}
        className="modal fade"
        centered
      >
        <Formik
          initialValues={{
            institution: props.document.tag.institution,
            score: props.document.tag.score,
            companyName: props.document.tag?.companyName || "",
            jobTitle: props.document.tag.jobTitle,
            testName: props.document.tag.testName,
            name: props.document.tag.name,
          }}
          onSubmit={async (
            values: any,
            { setSubmitting }: FormikHelpers<any>
          ) => {
            const resetStates = () => {
              // Reset state values

              values.institution = "";
              values.score = "";
              setissueDate("");
              values.testName = "";
              setjoiningDate("");
              setleavingDate("");
              values.jobTitle = "";
              values.name = "";
              values.companyName = "";
            };
            setloading(true);
            let documentData = [];

            if (props.document.documentType === "Education") {
              documentData.push({
                documentType: props.document.documentType.replace(/[\s/]/g, ""),
                tag: {
                  ...props.document.tag,
                  institution: values.institution,
                  score: values.score,
                  endYear: endDate,
                  endMonth: endDate,
                  startMonth: startDate,
                  startYear: startDate,
                },
                blobInfo: attachemnts,
              });
              resetStates();
            } else if (props.document.documentType === "EnglishEntranceTest") {
              documentData.push({
                documentType: props.document.documentType.replace(/[\s/]/g, ""),
                tag: {
                  ...props.document.tag,
                  issueDate: moment(issueDate).format(),
                  testName: values.testName,
                  score: values.score,
                  validDate: moment(validDate).format(),
                },
                blobInfo: attachemnts,
              });
              resetStates();
            } else if (
              props.document.documentType === "ProfessionalExperience"
            ) {
              documentData.push({
                documentType: props.document.documentType.replace(/[\s/]/g, ""),

                tag: {
                  ...props.document.tag,
                  startDate: moment(joiningDate).format(),
                  endDate: moment(leavingDate).format(),
                  companyName: values.companyName,
                  jobTitle: values.jobTitle,
                },
                blobInfo: attachemnts,
              });
              resetStates();
            } else {
              documentData.push({
                documentType: props.document.documentType.replace(/[\s/]/g, ""),
                tag: {
                  ...props.document.tag,

                  name: values.name,
                },
                blobInfo: attachemnts,
              });

              resetStates();
            }

            resetStates();

            try {
              const documentCreationPromises = documentData.map(
                async (data) => {
                  await updateDocument(props.document.id, data);
                }
              );

              await Promise.all(documentCreationPromises);

              // Reset all states together after the document creation
              resetStates();
            } catch (error) {
              // Handle errors
            } finally {
              setloading(false);
            }
            props.handleClose("");
            props.reloadDocuments();
            resetStates();
            setloading(false);
          }}
        >
          <Form>
            <Modal.Header closeButton>
              <Modal.Title className="modal-title">
                Edit {props.document.documentType} Document
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="h-100">
              <>
                <>
                  {props.document.documentType.toLowerCase() === "education" ? (
                    <>
                      <FormInput
                        id={"institution"}
                        name="institution"
                        placeholder=""
                        type="text"
                        label="Institution"
                      />
                      <FormInput
                        id="score"
                        name="score"
                        placeholder=""
                        type="text"
                        label="Score"
                      />
                      <div style={{ marginBottom: 24 }}>
                        <Label>Start Date</Label>
                        <ReactDatePicker
                          selected={startDate}
                          onChange={async (date: any) => {
                            setstartDate(date);
                          }}
                          showMonthYearPicker
                          dateFormat="MMM-yyyy"
                        />
                      </div>
                      <div style={{ marginBottom: 24 }}>
                        <Label>End Date</Label>
                        <ReactDatePicker
                          selected={endDate}
                          onChange={async (date: any) => {
                            setendDate(date);
                          }}
                          showMonthYearPicker
                          dateFormat="MMM-yyyy"
                        />
                      </div>
                    </>
                  ) : props.document.documentType.toLowerCase() ===
                    "englishentrancetest" ? (
                    <>
                      <FormInput
                        id={"testName"}
                        name="testName"
                        placeholder=""
                        type="text"
                        label="Test Name"
                      />
                      <FormInput
                        id="score"
                        name="score"
                        placeholder=""
                        type="text"
                        label="Score"
                      />

                      <div style={{ marginBottom: 24 }}>
                        <Label>Issue Date</Label>
                        <ReactDatePicker
                          selected={issueDate}
                          onChange={async (date: any) => {
                            setissueDate(date);
                          }}
                          dateFormat="dd-mm-yyyy"
                        />
                      </div>
                      <div style={{ marginBottom: 24 }}>
                        <Label>Valid Date</Label>
                        <ReactDatePicker
                          selected={validDate}
                          onChange={async (date: any) => {
                            setvalidDate(date);
                          }}
                          dateFormat="dd-mm-yyyy"
                        />
                      </div>
                    </>
                  ) : props.document.documentType ===
                    "ProfessionalExperience" ? (
                    <>
                      <FormInput
                        id={"companyName"}
                        name="companyName"
                        placeholder=""
                        type="text"
                        label="Company Name"
                      />
                      <FormInput
                        id="jobTitle"
                        name="jobTitle"
                        placeholder=""
                        type="text"
                        label="Job Title"
                      />
                      <div style={{ marginBottom: 24 }}>
                        <Label>Joining Date</Label>
                        <ReactDatePicker
                          selected={joiningDate}
                          onChange={async (date: any) => {
                            setjoiningDate(date);
                          }}
                          dateFormat="dd-mm-yyyy"
                        />
                      </div>

                      {props.document.tag.currentCompany ? null : (
                        <div style={{ marginBottom: 24 }}>
                          <Label>Leaving Date</Label>
                          <ReactDatePicker
                            selected={leavingDate}
                            onChange={async (date: any) => {
                              setleavingDate(date);
                            }}
                            dateFormat="dd-mm-yyyy"
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <FormInput
                      id={"name"}
                      name="name"
                      placeholder=""
                      type="text"
                      label="Description"
                    />
                  )}
                </>
                <div className="d-flex w-100  flex-column  align-items-center">
                  {attachemnts.map((a: any) => (
                    <div className="d-flex w-100  flex-row justify-content-between  align-items-center">
                      <SubText>{a.name}</SubText>
                      <div
                        style={{
                          float: "right",
                          cursor: "pointer",
                          color: "red",
                          fontWeight: 700,
                          fontSize: 14,
                        }}
                        onClick={() => {
                          setattachemnts(
                            attachemnts.filter((z: any) => a.name !== z.name)
                          );
                        }}
                      >
                        x
                      </div>
                    </div>
                  ))}
                </div>
                {/* <AddDocBtn onClick={() => props.setuploadModal(true)}>
                  + Add Docs
                </AddDocBtn> */}
              </>

              {/* <ButtonComponent btnType="dark" loading={loading} label="Login" /> */}
            </Modal.Body>
            <Modal.Footer
              className="d-flex justify-content-start"
              style={{ position: "relative", bottom: 0, width: "100%" }}
            >
              <ButtonComponent
                type="submit"
                disabled={loading}
                btnType="dark"
                loading={loading}
                label="Save"
              />
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </div>
  );
};
