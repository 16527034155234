/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import {
  DeleteIcon,
  DownloadIcon,
  EditIcon,
  InfoIcon,
  MenuIcon,
} from "../../Assets";
import Accordion from "react-bootstrap/Accordion";
import { downloadFile } from "../../Services/downloadFile";

import {
  AddDocBtn,
  AttachmentCon,
  DocumentSubtext,
  DocumentText,
  Card,
  AnchorTag,
} from "./style";
import { ViewerModal } from "../ViewerModal";
import { UploadDocument } from "../UploadDocument";
import { EditDocModal } from "../EditDocModal/EditDocModal";
import { Dropdown } from "react-bootstrap";
import { DeleteModal } from "../DeleteModal";
import {
  deleteStudentDocument,
  truncateString,
  updateDocument,
} from "../../Services/Function";
import { UploadLoader } from "../Loader/UploadLoader";
import { useSelector } from "react-redux";

interface IDocumentCardProps {
  name: string;
  data?: Array<object>;
  data2?: Array<object>;
  attachments?: string;
  score?: string;
  key: any;
  addDocs?: any;
  images?: any;
  document?: any;
  pdf?: any;
  reloadDocuments: any;
  uploading?: any;
  docs?: any;
}

const DocumentCard: React.FunctionComponent<IDocumentCardProps> = (props) => {
  const [viewerContent, setViewerContent] =
    React.useState<React.ReactNode | null>(null);

  const [modalShow, setmodalShow] = React.useState("");
  const [uploadModal, setuploadModal] = React.useState(false);
  const [images, setimages] = React.useState("");
  const [pdfs, setpdfs] = React.useState("");
  const [selectedDoc, setselectedDoc] = React.useState<any>({});
  const [deleteModal, setdeleteModal] = React.useState(false);
  const data = useSelector((state: any) => state.data);
  // Download file function

  return (
    <div key={props.key}>
      <Card>
        <div className="d-flex flex-column justify-content-center">
          <div
            className="d-flex flex-row align-items-center"
            style={{ marginBottom: 12 }}
          >
            <DocumentText>{props.name}</DocumentText>
            <InfoIcon style={{ margin: "0 12px 0 4px" }} />
            <AddDocBtn onClick={() => setuploadModal(true)}>
              + Add Docs.
            </AddDocBtn>
          </div>

          <div
            className="d-flex flex-row align-items-center"
            style={{ marginBottom: 12 }}
          >
            {props.data?.map((a: any, key: any) => (
              <React.Fragment key={key}>
                <DocumentSubtext fontWeight={a.fontWeight}>
                  {a.text}
                </DocumentSubtext>
                {a.pointer}
              </React.Fragment>
            ))}
          </div>

          <div
            className="d-flex flex-row align-items-center"
            style={{ marginBottom: 12 }}
          >
            {props.data2?.map((a: any, key: any) => (
              <React.Fragment key={key}>
                <DocumentSubtext fontWeight={a.fontWeight}>
                  {a.text}
                </DocumentSubtext>
                {a.pointer}
              </React.Fragment>
            ))}
          </div>
        </div>
        <div>
          <DocumentSubtext size="40px">{props.score}</DocumentSubtext>
        </div>
        <div style={{ position: "absolute", top: -12, right: 0 }}>
          <Dropdown style={{ marginBottom: 10 }}>
            <Dropdown.Toggle
              className="menu-dd"
              style={{ border: "none !important" }}
            >
              <MenuIcon style={{ cursor: "pointer" }} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  setmodalShow("edit");
                }}
                eventKey="1"
              >
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setmodalShow("delete");
                }}
                eventKey="1"
              >
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Card>

      <AttachmentCon>
        <Accordion defaultActiveKey="" flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              {props.uploading === props.document.id ||
              data.uploading === props.document.id ? (
                <UploadLoader height="50px" width="50px" />
              ) : (
                <DocumentSubtext fontWeight={600}>
                  {`Attachments (${props.images.length + props.pdf.length + (props.docs ? props.docs.length : 0)})`}
                </DocumentSubtext>
              )}
            </Accordion.Header>
            <Accordion.Body>
              {![...props.images, ...props.pdf, ...(props.docs || [])]
                .length ? (
                <p>No Document uploaded.</p>
              ) : (
                <ul>
                  {[...props.images].map((z: any, key: any) => (
                    <li key={key} className="d-flex justify-content-between">
                      <AnchorTag
                        onClick={(e) => {
                          setimages(z.url);
                          setpdfs("");
                          setmodalShow("view");
                        }}
                      >
                        {truncateString(z.name)}
                      </AnchorTag>
                      <div className="d-flex align-items-center">
                        <div
                          style={{ cursor: "pointer", marginRight: 10 }}
                          onClick={(e) => {
                            e.preventDefault();
                            const downloadUrl = `${z.url}`;

                            downloadFile(downloadUrl, z.name);
                          }}
                        >
                          <DownloadIcon />
                        </div>
                        <div
                          onClick={async () => {
                            setselectedDoc(z);
                            setdeleteModal(true);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <DeleteIcon />
                        </div>
                      </div>
                    </li>
                  ))}
                  {[...props.pdf].map((z: any, key: any) => (
                    <li key={key} className="d-flex justify-content-between">
                      <AnchorTag
                        onClick={(e) => {
                          setpdfs(z.url);
                          setimages("");
                          setmodalShow("view");
                        }}
                      >
                        {truncateString(z.name)}
                      </AnchorTag>
                      <div className="d-flex align-items-center">
                        <div
                          style={{ cursor: "pointer", marginRight: 10 }}
                          onClick={(e) => {
                            e.preventDefault();
                            const downloadUrl = `${z.url}&response-content-disposition=attachment; filename="${z.name}"`;

                            downloadFile(downloadUrl, z.name);
                          }}
                        >
                          <DownloadIcon />
                        </div>
                        <div
                          onClick={async () => {
                            setselectedDoc(z);
                            setdeleteModal(true);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <DeleteIcon />
                        </div>
                      </div>
                    </li>
                  ))}
                  {props.docs?.map((z: any, key: any) => (
                    <li key={key} className="d-flex justify-content-between">
                      <AnchorTag
                        onClick={(e) => {
                          setmodalShow("view");
                          setViewerContent(
                            <iframe
                              src={z.url}
                              title="Word Document Viewer"
                              style={{ width: "100%", height: "600px" }}
                            />
                          );
                        }}
                      >
                        {truncateString(z.name)}
                      </AnchorTag>
                      <div className="d-flex align-items-center">
                        <div
                          style={{ cursor: "pointer", marginRight: 10 }}
                          onClick={(e) => {
                            e.preventDefault();
                            const downloadUrl = `${z.url}&response-content-disposition=attachment; filename="${z.name}"`;

                            downloadFile(downloadUrl, z.name);
                          }}
                        >
                          <DownloadIcon />
                        </div>
                        <div
                          onClick={async () => {
                            setselectedDoc(z);
                            setdeleteModal(true);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <DeleteIcon />
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </AttachmentCon>

      {modalShow === "edit" ? (
        <EditDocModal
          show={modalShow === "edit"}
          handleClose={() => setmodalShow("")}
          document={props.document}
          setuploadModal={setuploadModal}
          reloadDocuments={props.reloadDocuments}
        />
      ) : null}
      {modalShow === "view" ? (
        <ViewerModal
          images={images}
          pdf={pdfs}
          show={modalShow === "view"}
          handleClose={() => setmodalShow("")}
          openViewer={setViewerContent}
        />
      ) : null}

      {modalShow === "delete" ? (
        <DeleteModal
          show={modalShow === "delete"}
          onHide={() => setmodalShow("")}
          onClick={async () => {
            await deleteStudentDocument(props.document.id);
            props.reloadDocuments();
            setmodalShow("");
          }}
        />
      ) : null}

      {uploadModal ? (
        <UploadDocument
          show={uploadModal}
          handleClose={() => setuploadModal(false)}
          document={props.document}
          reloadDocuments={props.reloadDocuments}
        />
      ) : null}

      {deleteModal ? (
        <DeleteModal
          onHide={() => setdeleteModal(false)}
          onClick={async () => {
            await updateDocument(props.document.id, {
              blobInfo: props.document.blobInfo.filter(
                (t: any) => t.blobUrl !== selectedDoc.url
              ),
            });
            props.reloadDocuments();
            setselectedDoc({});
            setdeleteModal(false);
            setselectedDoc({});
          }}
          show={deleteModal}
        />
      ) : null}
    </div>
  );
};

export default DocumentCard;
