// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const NavBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 32px;
  gap: 34px;
  width: 100%;
  height: 56px;
  left: 0px;
  top: 0px;
  position: sticky;
  z-index: 99;
  background: ${({ theme }) => theme.primaryColor};
  @media (max-width: 992px) {
    flex-direction: column;
  }
`;
export const NoAvatar = styled.div`
  background: center center / cover rgb(253 62 62);
  width: 40px;
  height: 40px;
  display: flex;
  border-radius: 20px;
  border: 1px solid rgb(255, 255, 255);
  position: absolute;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  right: 40px;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-family: "Inter";
  font-weight: 900;
  color: #fff;
  @media (max-width: 992px) {
    top: 16px;
    right: 80px;
  }
`;
export type NavTextProps = {
  fontWeight?: string;
};
export const NavText = styled.a<NavTextProps>(
  ({ fontWeight }) => `
  font-family: "Inter";
  font-style: normal;
  font-weight: ${fontWeight};
  cursor:pointer;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  color: #fff;
  border-bottom: ${fontWeight === "normal" ? "none" : "4px solid #fff"};
  padding:15px;
  &:hover{
    color: #fff;
    font-weight: 600;
  }
  
  `
);

export const AvatarCon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  border-radius: 20px;
  border: 1px solid rgb(255, 255, 255);
  position: absolute;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  right: 40px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-size: 20px;
  font-family: Inter;
  font-weight: 900;
  color: rgb(255, 255, 255);
  @media (max-width: 992px) {
    top: 16px;
    right: 80px;
  }
`;
export const NavBorder = styled.div``;
export const SearchBar = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 8px;
  gap: 4px;
  width: 320px;
  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  padding-left: 25px;
  ::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #667085;
  }
  &:focus {
    border: 1px solid red;
    outline: none;
  }
`;
