import React, { useState } from "react";
import styled from "styled-components";
import { CrownIcon, FirstBadge, SecondBadge, ThirdBadge } from "../../Assets";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./SalesComponent.css"; // Make sure to create the corresponding CSS file for animations
import { Label } from "../FormInput/styled";

// Define the Card component styling
const Card = styled.div`
  background: #fff;
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
  height: 100%;
`;

// Define the CardHeader component styling
const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--Admin-Divider, #ebebeb);
`;

// Define the HeaderText component styling
const HeaderText = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: #1e2432;
`;

// Define the PercentageText component styling
const PercentageText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: rgba(190, 161, 255, 0.1);
  font-size: 20px;
`;

// Define the Table component styling
const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
  table-layout: fixed;
`;

// Define the TableHeader component styling
const TableHeader = styled.th<{ isSorted: boolean }>`
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: #1e2432;
  text-align: center;
  padding: 12px 0;
  cursor: pointer;
  transition:
    background 0.3s,
    border-bottom 0.3s;

  span {
    display: inline-block;
    background: ${({ isSorted }) =>
      isSorted ? "rgba(190, 161, 255, 0.1)" : "transparent"};
    border-radius: 90px;
    padding: 10px 8px;
  }
`;

// Define the TableRow component styling
const TableRow = styled.tr`
  background: rgba(190, 161, 255, 0.1);
  border-radius: 12px;
  margin-bottom: 10px;
  display: table-row;

  &:last-child {
    margin-bottom: 0;
  }

  td:first-child {
    border-radius: 12px 0 0 12px;
  }

  td:last-child {
    border-radius: 0 12px 12px 0;
  }
`;

// Define the TableCell component styling
const TableCell = styled.td`
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: rgba(30, 36, 50, 0.8);
  padding: 12px;
  text-align: center;
  white-space: nowrap;
  min-width: 80px; /* Prevent cell data from overlapping */
`;

// Define the AvatarContainer component styling
const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
`;

// Define the BadgeContainer component styling
const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
`;

// Define the Avatar component styling
const Avatar = styled.img`
  width: 32px;
  height: 32px;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 16px;
  border: 1px solid #7f56d9;
  margin-right: 8px;
  position: relative;
`;

type Props = {
  header?: string;
  th?: string[];
  icon?: any;
  data?: any;
  users?: any;
  bookingsSum?: number;
  totalAmountSum?: number;
  studentsTab?: any;
};

export const SalesComponent: React.FC<Props> = ({
  header,
  icon,
  data,
  users,
  totalAmountSum,
  bookingsSum,
  studentsTab,
  th = ["Manager", "N° Bookings", "T. Amount"],
}) => {
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: string;
  } | null>(null);

  const sortedData = React.useMemo(() => {
    if (sortConfig !== null) {
      const sorted = [...data].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
      return sorted;
    }
    return data;
  }, [data, sortConfig]);

  const requestSort = (key: string) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getIcon = (index: number) => {
    if (index === 0)
      return <FirstBadge color="" style={{ width: 30, height: 30 }} />;
    if (index === 1)
      return <SecondBadge color="" style={{ width: 30, height: 30 }} />;
    if (index === 2)
      return <ThirdBadge color="" style={{ width: 30, height: 30 }} />;
    return <div style={{ minWidth: 30, minHeight: 30 }} />;
  };

  return (
    <Card>
      <CardHeader>
        <HeaderText>{header}</HeaderText>
        <PercentageText>{icon}</PercentageText>
      </CardHeader>
      <Table>
        <thead>
          <TableRow style={{ background: "#fff" }}>
            {th.map((a, index) => {
              const key =
                index === 0 ? "name" : index === 1 ? "bookings" : "totalAmount";
              return (
                <TableHeader
                  style={{ textAlign: index === 0 ? "left" : "center" }}
                  key={index}
                  isSorted={sortConfig?.key === key}
                  onClick={() => requestSort(key)}
                >
                  <span>{a}</span>
                </TableHeader>
              );
            })}
          </TableRow>
        </thead>
        <TransitionGroup component="tbody">
          {sortedData
            ?.slice(0, 5)
            .filter((a: any) => a.tag_salesPerson !== null)
            .map((manager: any, index: any) => {
              const user = users?.find(
                (x: any) =>
                  manager.tag_salesPerson === x.id ||
                  manager.tag_incharge === x.id ||
                  manager.tag_checkedBy === x.id ||
                  manager.tag?.salesPerson === x.id ||
                  manager.tag?.operation === x.id ||
                  manager.tag?.operations === x.id ||
                  manager.tag?.accountManager === x.id
              );

              return (
                <CSSTransition key={index} timeout={500} classNames="fade">
                  <TableRow>
                    <TableCell>
                      <AvatarContainer>
                        <BadgeContainer>{getIcon(index)}</BadgeContainer>
                        <div style={{ position: "relative" }}>
                          {user?.avatar ? (
                            <Avatar src={user.avatar} alt="img" />
                          ) : (
                            <div
                              style={{
                                background: "#ccc",
                                height: 32,
                                width: 32,
                                borderRadius: 16,
                              }}
                            />
                          )}
                          {index === 0 && (
                            <CrownIcon
                              style={{
                                position: "absolute",
                                left: 10,
                                top: -7,
                              }}
                              color={""}
                            />
                          )}
                        </div>
                        {studentsTab ? null : (
                          <div>{user ? user.name : "N/A"}</div>
                        )}
                      </AvatarContainer>
                    </TableCell>
                    {studentsTab ? (
                      <>
                        <TableCell>{manager.appliedCount}</TableCell>
                        <TableCell>{manager.enrolledCount}</TableCell>
                        <TableCell>
                          {(manager.enrolledCount / manager.appliedCount) * 100}
                          %
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell>{manager.bookings}</TableCell>
                        {header === "IELTS Booking" ? null : (
                          <TableCell>{manager.totalAmount} KD</TableCell>
                        )}
                      </>
                    )}
                  </TableRow>
                </CSSTransition>
              );
            })}
        </TransitionGroup>
      </Table>
      {header === "Office Fees" ? (
        <div
          style={{ padding: "20px 0" }}
          className="d-flex justify-content-around"
        >
          <Label className="d-flex flex-column align-items-center">
            <b>{bookingsSum}</b>
            👩🏼‍🎓 Total N° of Students
          </Label>
          <Label className="d-flex flex-column align-items-center">
            <b>{totalAmountSum}</b>
            🤑 Total Amount
          </Label>
        </div>
      ) : null}
    </Card>
  );
};
