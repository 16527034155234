import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { CountryInputComponent } from "../CountryInputComponent";
import { ButtonComponent } from "../ButtonComponent";
import { Form, Formik, FormikHelpers, Field } from "formik";
import { FormInput } from "../FormInput";
import { Label, Required } from "../AboutPage/styled";
import {
  createCourse,
  createCourseDetails,
  getCourseBycourseRefId,
  getCourseDetailByUni,
  getUniversityByuniRefId,
  searchSubjects,
} from "../../Services/Function";
import { debounce } from "debounce";
import { LocationForm } from "../LocationForm/LocationForm";
import { ToggleButton } from "../ToggleButton";
import { IntakeMonthsSelector } from "../IntakeMonthsSelector";
import CampusForm from "../CampusForm";
import { UniversityDDComponent } from "../UniversityDDComponent";
import { MultipleInputComponent } from "../MultipleInputComponent";

type Props = {
  show: boolean;
  handleClose: any;
  applyFilter?: any;
  setuniversity?: Function;
  university: any;
  searchUniversity: any;
  reloadCourses: any;
  loading: any;
};

export const AddCourse = (props: Props) => {
  const [loading, setloading] = useState(false);
  const [selectedUniversity, setselectedUniversity] = useState<any>({});
  const [selectedSubject, setselectedSubject] = useState<any>([]);
  const [subjects, setsubjects] = useState([]);
  const [searchText, setsearchText] = useState("");
  const [placementAvailable, setplacementAvailable] = useState(false);
  const [searchText1, setsearchText1] = useState("");
  const [universityDetails, setuniversityDetails] = useState<any>("");
  const { university, setuniversity, searchUniversity } = props;

  const [selectedMonths, setSelectedMonths] = useState<string[]>([]);

  const handleSaveMonths = (selected: string[]) => {
    setSelectedMonths(selected);
  };

  const searchSub = debounce(async (e: any) => {
    if (!e.target.value) {
      setsubjects([]);
    } else {
      const res = await searchSubjects({
        text: e.target.value,
      });
      setsubjects(res?.results);
    }
  }, 500);

  const convertToSlug = (text: string): string => {
    return text
      .toLowerCase()
      .replace(/[^a-z0-9 -]/g, "") // Remove special characters except spaces and hyphens
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/-+/g, "-"); // Replace multiple consecutive hyphens with a single hyphen
  };

  const courseLevels = [
    "Undergraduate",
    "Postgraduate",
    "Foundation",
    "Doctorate",
  ];
  const currencies = ["KWD", "GBP", "USD"];
  const providers = [
    "Kaplan",
    "Study Group",
    "INTO",
    "Navitas",
    "QA",
    "OnCampus",
    "In House Foundation",
  ];

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        className="modal fade "
        centered
      >
        <Formik
          initialValues={{
            name: "",
            courseLevel: "",
            attendanceTypes: "",
            approxAnnualFee: "",
          }}
          onSubmit={async (
            values: any,
            { setSubmitting }: FormikHelpers<any>
          ) => {
            setloading(true);

            const courseDetail = await getCourseDetailByUni(
              selectedUniversity.slug
            );

            await createCourse({
              name: values.name,
              institution: selectedUniversity.name,
              subjects: selectedSubject.map((a: any) => a.slug),
              courseLevel: values.courseLevel,
              attendanceTypes: [values.attendanceTypes],
              approxAnnualFee: values.approxAnnualFee,
              provider: values.provider,
              courseDurationValues: [parseInt(values.courseDurationValues)],
              currency: values.currency,
              locations: [
                {
                  key: "United Kingdom",
                  value: "",
                },
              ],
              institutionSlug: selectedUniversity.slug,
              intakeMonths: selectedMonths,
              courseRefId: `${selectedUniversity.slug}|${convertToSlug(
                values.name
              )}|${convertToSlug(values.courseLevel)}`,
            });

            await createCourseDetails({
              name: values.name,
              institution: {
                ...selectedUniversity,
                overview: `${universityDetails.overview}`,
                bannerUrl: universityDetails.bannerUrl,
              },
              subjects: selectedSubject.map((a: any) => a.slug),
              courseLevel: values.courseLevel,
              attendanceTypes: [values.attendanceTypes],
              courseSummary: "N/A",
              approxAnnualFee: values.approxAnnualFee,
              currency: values.currency,
              provider: values.provider,
              locations: [
                {
                  key: "United Kingdom",
                  value: "",
                },
              ],
              campusList: courseDetail.campusList,
              courseIntakes: selectedMonths.map((a) => {
                return { intakeMonth: a };
              }),
              courseRefId: `${selectedUniversity.slug}|${convertToSlug(
                values.name
              )}|${convertToSlug(values.courseLevel)}`,
            });
            // setstudent(res);
            props.reloadCourses();
            setloading(false);
            props.handleClose();
          }}
        >
          {({ values }) => (
            <Form autoComplete="new-password">
              <Modal.Header closeButton>
                <Modal.Title className="modal-title">
                  Add New Course
                </Modal.Title>
              </Modal.Header>
              <Modal.Body
                className="h-100"
                style={{ padding: 15, overflow: "scroll" }}
              >
                <>
                  <div>
                    <Label style={{ color: "#344054", fontWeight: "normal" }}>
                      <Required>*</Required>Course Level
                    </Label>
                    <Field
                      className="form-input"
                      as="select"
                      name="courseLevel"
                    >
                      <option value="" disabled selected></option>
                      {courseLevels.map((level, key) => (
                        <option key={key} value={level}>
                          {level}
                        </option>
                      ))}
                    </Field>
                  </div>
                  {values.courseLevel === "Foundation" ? (
                    <div>
                      <Label style={{ color: "#344054", fontWeight: "normal" }}>
                        <Required>*</Required>Provider
                      </Label>
                      <Field className="form-input" as="select" name="provider">
                        <option value="" disabled selected></option>
                        {providers.map((level, key) => (
                          <option key={key} value={level}>
                            {level}
                          </option>
                        ))}
                      </Field>
                    </div>
                  ) : null}
                  <div>
                    <Label style={{ color: "#344054", fontWeight: "normal" }}>
                      <Required>*</Required>Attendance Type
                    </Label>
                    <Field
                      className="form-input"
                      as="select"
                      name="attendanceTypes"
                    >
                      <option value="" disabled selected>
                        Select an option
                      </option>
                      {["FullTime", "DistanceLearning"].map((level, key) => (
                        <option key={key} value={level}>
                          {level}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <FormInput
                    id="name"
                    autoComplete="off"
                    name="name"
                    placeholder=""
                    type="text"
                    label="Name"
                  />

                  <div style={{ marginBottom: 14 }}>
                    <Label style={{ color: "#344054", fontWeight: "normal" }}>
                      <Required>*</Required>University
                    </Label>
                    <CountryInputComponent
                      selectedCountry={selectedUniversity}
                      setsearchText={setsearchText}
                      autoComplete="off"
                      university
                      addCourse
                      onClickFn={async (data: any) => {
                        const res = await getUniversityByuniRefId(
                          data.institutionRefId
                        );
                        setuniversityDetails(res[0]);
                      }}
                      setselectedCountry={setselectedUniversity}
                      removeContry={() => setselectedUniversity({})}
                      arabic={false}
                      searchCountry={searchUniversity}
                      countries={university}
                      setcountries={setuniversity}
                      searchText={searchText}
                      placeholder={""}
                    />
                  </div>

                  <div style={{ marginBottom: 24 }}>
                    <Label style={{ color: "#344054", fontWeight: "normal" }}>
                      <Required>*</Required>Subject
                    </Label>

                    <UniversityDDComponent
                      title="Subjects"
                      style={{ zIndex: 1 }}
                      ddClass="subject-dd"
                      // ifSelected={selectedSubject.length}
                      subjectCount={selectedSubject.length}
                      element={
                        <div style={{ paddingTop: 15 }}>
                          <MultipleInputComponent
                            autoComplete={"off"}
                            selectedUniversity={selectedSubject}
                            setselectedUniversity={setselectedSubject}
                            setsearchText={setsearchText1}
                            arabic={false}
                            coursePage={false}
                            // conStyle={{ flexDirection: "column" }}
                            filterCourses={async (sub: any) => {}}
                            multiSelectClass="subject-multiselect-input"
                            className="subject-input"
                            universities={subjects}
                            searchText={searchText1}
                            searchUniversity={(e: any) => searchSub(e)}
                            setuniversities={setsubjects}
                            seterrorMessage={() => {}}
                            error={""}
                          />
                        </div>
                      }
                      data={[]}
                      onClick={async (data: React.SetStateAction<string>) => {}}
                      selected={"Subjects"}
                    />
                  </div>

                  <FormInput
                    id="approxAnnualFee"
                    name="approxAnnualFee"
                    placeholder=""
                    type="number"
                    label="Approx Annual Fee"
                  />
                  <div>
                    <Label style={{ color: "#344054", fontWeight: "normal" }}>
                      <Required>*</Required>Currency
                    </Label>
                    <Field className="form-input" as="select" name="currency">
                      <option value="" disabled selected></option>
                      {currencies.map((level, key) => (
                        <option key={key} value={level}>
                          {level}
                        </option>
                      ))}
                    </Field>
                  </div>

                  <div style={{ marginTop: 24 }}>
                    <IntakeMonthsSelector
                      selectedMonths={selectedMonths}
                      onSave={handleSaveMonths}
                    />
                  </div>
                  <div style={{ marginTop: 24 }}>
                    <Label style={{ color: "#344054", fontWeight: "normal" }}>
                      <Required>*</Required>Course Duration
                    </Label>
                    <Field
                      className="form-input"
                      as="select"
                      name="courseDurationValues"
                    >
                      <option value="" disabled selected></option>
                      {[
                        { key: "1 year", value: 360 },
                        { key: "2 year", value: 720 },
                        { key: "3 year", value: 1080 },
                        { key: "4 year", value: 1440 },
                        { key: "5 year", value: 1800 },
                      ].map((level, key) => (
                        <option key={key} value={level.value}>
                          {level.key}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <div style={{ marginTop: 24 }} className="d-flex flex-column">
                    <Label
                      style={{
                        color: "#344054",
                        fontWeight: "normal",
                        marginBottom: 4,
                      }}
                    >
                      Placement Available
                    </Label>

                    <ToggleButton
                      label={"Placement Available"}
                      style={{ margin: 0 }}
                      onChange={() =>
                        setplacementAvailable(!placementAvailable)
                      }
                      checked={placementAvailable}
                    />
                  </div>
                </>{" "}
                <div className="d-flex" style={{ marginTop: 50 }}>
                  <ButtonComponent
                    label={"Submit"}
                    loading={loading}
                    btnType={"dark"}
                  />
                  <ButtonComponent
                    label={"Close"}
                    loading={loading}
                    style={{ marginLeft: 20 }}
                    type="button"
                    onClick={() => props.handleClose()}
                    btnType={"light"}
                  />
                </div>
                {/* <ButtonComponent btnType="dark" loading={loading} label="Login" /> */}
              </Modal.Body>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};
