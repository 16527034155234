import * as React from "react";
import {
  ApplicationStatus,
  HoverCon,
  Table,
  TBody,
  Td,
  TdEmail,
  TdText,
  Th,
  THead,
  Tr,
  UserSection,
} from "./styled";
import { getApplications } from "../../Services/Function";
import { useNavigate } from "react-router-dom";
import moment from "moment";

interface IApplicationViewProps {
  reloadStudents: Function;
  students: Array<any>;
  page: any;
  selectedAppStatus: any;
  intakeYear: any;
  intakeMonth: any;
  confrimStatus: any;
  setApplications: any;
  applications: any;
}

export const ApplicationView: React.FC<IApplicationViewProps> = ({
  students,
  selectedAppStatus,
  intakeYear,
  intakeMonth,
  confrimStatus,
  setApplications,
  applications,
}) => {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await getApplications({
          status: selectedAppStatus.length > 0 ? selectedAppStatus : undefined,
          intakeYear: intakeYear,
          intakeMonth: intakeMonth,
          confrimStatus: confrimStatus.length > 0 ? confrimStatus : undefined,
        });
        setApplications(res);
      } catch (error) {
        alert(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const applicationPhase = [
    "INITIATED",
    "SUBMITTED",
    "CONDITIONAL OFFER",
    "UNCONDITIONAL OFFER",
    "CONFIRMATION",
    "FG/BS",
    "CAS RECEIVED",
    "DONE",
  ];

  const filterApplicationsByStatus = (status: string) => {
    return applications.filter((app: any) =>
      app.portalApplicationStatus?.applicationPhases.some(
        (phase: any) =>
          phase.phaseState === "Completed" &&
          phase.status.toUpperCase() === status.toUpperCase() &&
          phase.isPrevious
      )
    );
  };

  // Filter out students who have no applications after applying the status filter
  const filteredStudents = students.filter((student) =>
    applicationPhase.some((phase) =>
      filterApplicationsByStatus(phase).some((app: any) =>
        student.applications.includes(app._id)
      )
    )
  );

  return (
    <div className="table-responsive">
      <Table className="table">
        <THead>
          <Tr>
            <Th scope="col">Name</Th>
            {applicationPhase.map((phase) => (
              <Th scope="col" key={phase}>
                {phase}
              </Th>
            ))}
          </Tr>
        </THead>
        {filteredStudents.map((student) => (
          <TBody key={student.id}>
            <Tr style={{ borderBottom: "1px solid #eaecf0" }}>
              <Td>
                <UserSection>
                  <div className="d-flex flex-column">
                    <TdText
                      onClick={() => navigate(`/student/about/${student.id}`)}
                    >
                      {student.firstName} {student.lastName}
                    </TdText>
                    <TdEmail>{student.phoneNo}</TdEmail>
                  </div>
                </UserSection>
              </Td>
              {applicationPhase.map((phase) => (
                <Td key={phase}>
                  <div className="d-flex flex-row align-items-start h-100">
                    {filterApplicationsByStatus(phase)
                      .filter((app: any) =>
                        student.applications.includes(app._id)
                      )
                      .map((app: any) => {
                        return (
                          <ApplicationStatus
                            key={app._id}
                            onClick={() =>
                              navigate(
                                `/student/applicationDetails/${student.id}/${app._id}`
                              )
                            }
                            style={{}}
                            borderColor={app.rejected ? "grey" : "black"}
                          >
                            <img
                              style={{
                                width: "100%",
                                borderRadius: 12,
                                height: "100%",
                                opacity: app.rejected ? 0.5 : 1,
                              }}
                              src={`https://storage.googleapis.com/uapply/${app.institute.logoUrl}`}
                              alt="img"
                            />
                            <HoverCon style={{ opacity: 1 }} className="text">
                              <div>
                                {app.institute.name}{" "}
                                {app.provider ? `(${app.provider})` : ""}
                              </div>
                              <div>{app.courseLevel}</div>
                              <div>{app.courseName}</div>
                              <div>{`${app.intakeMonth} ${app.intakeYear}`}</div>
                              <div>{`Created At: ${moment(app.createdAt).format(
                                "DD-MM-YYYY hh:mma"
                              )}`}</div>
                            </HoverCon>
                          </ApplicationStatus>
                        );
                      })}
                  </div>
                </Td>
              ))}
            </Tr>
          </TBody>
        ))}
      </Table>
    </div>
  );
};
