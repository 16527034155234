/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Modal, Dropdown } from "react-bootstrap";
import { ButtonComponent } from "./ButtonComponent";
import { Form, Formik } from "formik";
import { createApplication, updateStudent } from "../Services/Function";
import { useSelector } from "react-redux";
// I;
// import moment from "moment";

type Props = {
  show: any;
  handleClose: any;
  courseDetail: any;
  selectedStudent: any;
  selectedCourse: any;
  reoadStudents: any;
  reloadCourses: any;
};

export const StartApplication = (props: Props) => {
  const [loading, setloading] = useState(false);
  const [intakeMonth, setintakeMonth] = useState("");
  const [intakeYear, setintakeYear] = useState("");
  const [campus, setcampus] = useState<any>("");
  const [applicationArr, setapplicationArr] = useState(
    props.selectedStudent.applications
  );
  const [agent, setagent] = useState("");
  const [dates, setdates] = useState<any>([]);
  const auth = useSelector((state: any) => state.auth);

  useEffect(() => {
    let months: any = [];
    for (let i = 0; i < props.courseDetail?.courseIntakes.length; i++) {
      const intakeMonth = props.courseDetail?.courseIntakes[i].intakeMonth;
      months.push(intakeMonth);
    }
    months = months.filter(
      (value: any, index: any) => months.indexOf(value) === index
    );
    console.log("first", months);
    const data: any = getDates(months);
    setdates(data);
  }, []);

  function handleClick(data: any) {
    setapplicationArr([...applicationArr, data]);
  }

  function getDates(months: any) {
    const currentYear = new Date().getFullYear();
    const nextTwoYears = [currentYear, currentYear + 1, currentYear + 2];
    const result = [];

    for (const year of nextTwoYears) {
      for (const month of months) {
        const monthNumber = new Date(`${month} 1, ${year}`).getMonth();
        const date = new Date(year, monthNumber, 1);
        const monthName = date.toLocaleDateString("en-US", { month: "long" });
        const yearNumber = date.getFullYear();
        result.push({ month: monthName, year: yearNumber });
      }
    }

    return result;
  }

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        keyboard={false}
        // id="modal-right"
        className="modal fade"
        centered
        size="sm"
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">Start Application</Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={{}}
          onSubmit={async function () {
            try {
              setloading(true);
              const res = await createApplication({
                startDate: new Date(),
                editor: auth.userDetails,
                endDate: null,
                campus: campus,
                agent: agent,
                provider: props.selectedCourse.provider,
                studentId: props.selectedStudent.id,
                courseLevel: props.courseDetail.courseLevel,
                intakeYear: intakeYear,
                intakeMonth: intakeMonth,
                documents: "60f2c796300f4950d710e2d4",
                course: props.selectedCourse.id,
                courseName: props.selectedCourse.name,
                institute: props.courseDetail.institution,
                applicationId: `A-${props.selectedStudent.refrenceNo}-${
                  applicationArr.length < 10
                    ? `0${applicationArr.length + 1}`
                    : `${applicationArr.length + 1}`
                }`,
              });
              handleClick(res._id);
              //   if (res) {
              await updateStudent(props.selectedStudent.id, {
                applications: [...applicationArr, res._id],
                stage: "Applied",
              });
              //   }
              props.handleClose();
              props.reloadCourses();
              props.reoadStudents();

              setloading(false);
            } catch (error) {
              console.log(error);
              setloading(false);
            }
          }}
        >
          {({ values }) => (
            <Form className="d-flex flex-column" style={{ marginTop: 20 }}>
              <Modal.Body>
                <Dropdown style={{}}>
                  <Dropdown.Toggle>
                    {" "}
                    {intakeMonth} {intakeYear}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {dates.map((a: any, key: any) => (
                      <Dropdown.Item
                        key={key}
                        onClick={() => {
                          setintakeMonth(a.month);
                          setintakeYear(a.year);
                        }}
                        eventKey="1"
                      >
                        {a.month} {a.year}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>

                <Dropdown style={{ marginTop: 10 }}>
                  <Dropdown.Toggle>{campus.name || ""}</Dropdown.Toggle>

                  <Dropdown.Menu>
                    {props.courseDetail?.campusList.map((a: any, key: any) => (
                      <Dropdown.Item
                        key={key}
                        onClick={() => {
                          setcampus(a);
                        }}
                        eventKey="1"
                      >
                        {a.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown style={{ marginTop: 10 }}>
                  <Dropdown.Toggle>{agent || ""}</Dropdown.Toggle>

                  <Dropdown.Menu>
                    {["Ulearn", "SIUK", "GESCO"].map((a: any, key: any) => (
                      <Dropdown.Item
                        key={key}
                        onClick={() => {
                          setagent(a);
                        }}
                        eventKey="1"
                      >
                        {a}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Modal.Body>
              <Modal.Footer>
                <ButtonComponent
                  btnType="dark"
                  disabled={!intakeMonth || !intakeYear || !agent}
                  loading={loading || !intakeMonth || !intakeYear}
                  label="Apply"
                />
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};
