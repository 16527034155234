// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Th = styled.th`
  background-color: #f9fafb !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #667085;
  border-bottom: 1px solid #eaecf0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 12px 24px !important;
`;

export const Table = styled.table`
  border: 1px solid #eaecf0;
  border-radius: 8px;
  border-collapse: separate;
  border-spacing: 0;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;

export const Tr = styled.tr`
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  :last-child {
    border-color: transparent;
  }
  :first-child td:first-child {
    border-top-left-radius: 8px;
  }
  :last-child td:first-child {
    border-bottom-left-radius: 8px;
  }
  :first-child td:last-child {
    border-top-right-radius: 8px;
  }
  :last-child td:last-child {
    border-bottom-right-radius: 8px;
  }
`;
export const THead = styled.thead``;
export const TBody = styled.tbody`
  height: auto;
  width: 100%;
  overflow-y: auto;

  overflow-x: hidden;
`;
export const TFoot = styled.tfoot``;

export const Td = styled.td`
  padding: 16px 24px !important;
  vertical-align: middle;
  border-right: 1px solid #eaecf0;
`;

export const UserSection = styled.div`
  display: flex;
  flex-direction: row;
`;
export const TdTextBold = styled.span`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #1d2939;
`;
export const TdText = styled.span`
  font-family: "Inter";
  font-style: normal;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1d2939;
  width: max-content;
`;
export const TdEmail = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
`;
export type StatusProps = {
  background?: string;
  color: string;
};
export const Status = styled.div<StatusProps>(
  ({ background, color }) => `
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 10px;
    height: 20px;
    width: max-content;
    background: ${background};
    border-radius: 10px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: ${color};
  `
);

export const ApplicationCard = styled.div`
  min-width: 254px;
  height: 110px;
  background: #ffff;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 12px;
`;

export const Course = styled.span`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #344563;
  padding: 12px;
`;

export const CourseLevel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 8px 2px 12px;
  gap: 2px;
  width: 37px;
  height: 24px;
  background: #eaecf0;
  border-radius: 6px 0px 0px 6px;
  margin: 12px 0 12px 12px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #7a869a;
`;

export const HoverCon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  background-color: #fff;
  position: absolute;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 8px;
  div {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    padding-bottom: 8px;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
  }
`;

export type ApplicationStatusProps = {
  borderColor?: string;
};
export const ApplicationStatus = styled.div<ApplicationStatusProps>(
  ({ borderColor }) => `
  border-radius: 8px;
  margin-right:8px;
  cursor:pointer;
  width: 40px;
  // padding: 8px 0px 8px 8px;
  border: 1px solid ${borderColor};
  background: #FFF;
  .text {
    opacity: 0;
    display:none;
    position:absolute;
    transition: opacity 0.3s ease-in-out;
  }
  &:hover .text {
    opacity: 1;
    display:block;
    
  }
  `
);
