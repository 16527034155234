import Compressor from "compressorjs";
import { PDFDocument } from "pdf-lib";
import {
  UPLOADED,
  WA_KEY,
  SET_UPLOAD_PROGRESS,
  SET_UPLOADING,
  ANIMATION_FLAG,
} from "./types";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase/firebase";
import {
  getUploadState,
  saveUploadState,
  clearUploadState,
} from "../../utilities/uploadUtils";

export const saveWaKey = (waKey) => {
  return async (dispatch) => {
    try {
      await dispatch({
        type: WA_KEY,
        payload: waKey,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const fileUploaded = (uploaded) => {
  return async (dispatch) => {
    try {
      await dispatch({
        type: UPLOADED,
        payload: uploaded,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const setUploadProgress = (progress) => {
  return async (dispatch) => {
    try {
      await dispatch({
        type: SET_UPLOAD_PROGRESS,
        payload: progress,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const setUploading = (isUploading) => {
  return async (dispatch) => {
    try {
      await dispatch({
        type: SET_UPLOADING,
        payload: isUploading,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const setAnimationFlag = (animationFlag) => {
  return async (dispatch) => {
    try {
      await dispatch({
        type: ANIMATION_FLAG,
        payload: animationFlag,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const handleFileUpload = (files) => {
  return async (dispatch) => {
    const uploadPromises = files.map(async (file) => {
      let compressedFile = file;
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (fileExtension === "pdf") {
        // Step 3: Compress PDF
        compressedFile = await compressPDF(file);
      } else if (["jpg", "jpeg", "png"].includes(fileExtension)) {
        // Step 4: Compress Image
        compressedFile = await compressImage(file);
      }
      // Create a unique filename with a timestamp
      const originalName = file.name;
      const timestamp = Date.now();
      const uniqueFileName = `${originalName}_${timestamp}.${fileExtension}`;

      const uploadState = getUploadState(uniqueFileName);
      const fileRef = ref(storage, `uploads/${uniqueFileName}`);

      let uploadTask;
      if (uploadState) {
        // Resume upload
        uploadTask = uploadBytesResumable(fileRef, compressedFile, {
          customMetadata: {
            uploadState: JSON.stringify(uploadState),
          },
        });
      } else {
        // Start new upload
        uploadTask = uploadBytesResumable(fileRef, compressedFile);
      }

      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            saveUploadState(uniqueFileName, progress);
          },
          (error) => {
            reject(error);
          },
          async () => {
            clearUploadState(uniqueFileName);
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

            resolve({ url: downloadURL, name: uniqueFileName });
          }
        );
      });
    });

    try {
      const uploadResults = await Promise.all(uploadPromises);
      return uploadResults;
    } catch (error) {
      console.error("Upload failed:", error);
      throw error;
    }
  };
};

// Step 3: Function to compress the PDF
const compressPDF = async (file) => {
  try {
    // Read the file as an ArrayBuffer
    const arrayBuffer = await file.arrayBuffer();
    const pdfDoc = await PDFDocument.load(arrayBuffer);

    // Optimize PDF (basic stream compression)
    const compressedPdfBytes = await pdfDoc.save({ useObjectStreams: true });

    // Convert back to a Blob for uploading
    return new Blob([compressedPdfBytes], {
      type: "application/pdf",
      name: file.name,
    });
  } catch (error) {
    console.error("PDF compression failed:", error);
    throw error;
  }
};

// Step 4: Function to compress the image
const compressImage = async (file) => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 0.8, // Adjust the quality (0-1)
      convertSize: 5000000, // Convert images larger than 5MB
      success: (compressedFile) => {
        resolve(compressedFile);
      },
      error: (error) => {
        console.error("Image compression failed:", error);
        reject(error);
      },
    });
  });
};
