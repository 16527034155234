import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import imageCompression from "browser-image-compression";
import { PDFDocument } from "pdf-lib";

const firebaseConfig = {
  apiKey: "AIzaSyDQkk9p4h9pfXSvuPmU95Od3MCvEgigBUg",
  authDomain: "uapply-fd1d1.firebaseapp.com",
  projectId: "uapply-fd1d1",
  storageBucket: "uapply-fd1d1.appspot.com",
  messagingSenderId: "1035612220208",
  appId: "1:1035612220208:web:5e82ce1606aebdf3def6f5",
  measurementId: "G-8JXMH47EW3",
};

firebase.initializeApp(firebaseConfig);

const MAX_FILE_SIZE_MB = 8;

interface FileWithURL {
  url: string;
  name: string;
}

// Validate file size and type
const validateFile = (file: File): void => {
  if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
    throw new Error("File size is too large. Max size is 8 MB.");
  }

  const allowedFileTypes = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];
  if (!allowedFileTypes.includes(file.type)) {
    throw new Error(
      "Invalid file type. Allowed types: JPEG, JPG, PNG, PDF, DOC, DOCX"
    );
  }
};

// Compress images
const compressImage = async (file: File): Promise<File> => {
  try {
    const compressedFile = await imageCompression(file, {
      maxSizeMB: 1,
      maxWidthOrHeight: 800,
      useWebWorker: true,
      maxIteration: 10,
      initialQuality: 0.85,
    });
    return compressedFile as File;
  } catch (error) {
    console.error("Image compression error:", (error as Error).message);
    throw new Error("Failed to compress image.");
  }
};

// Optimize PDF
const optimizePDF = async (file: File): Promise<Blob> => {
  try {
    const pdfBytes = await file.arrayBuffer();
    const pdfDoc = await PDFDocument.load(pdfBytes, { ignoreEncryption: true });
    const pages = pdfDoc.getPages();

    for (let i = 0; i < pages.length; i++) {
      const page = pages[i];
      page.scale(0.8, 0.8);
    }

    const optimizedPdfBytes = await pdfDoc.save();
    return new Blob([optimizedPdfBytes], { type: "application/pdf" });
  } catch (error) {
    console.error("PDF optimization error:", (error as Error).message);
    throw new Error("Failed to optimize PDF.");
  }
};

// Upload files
const uploadImage = async (files: any): Promise<FileWithURL[]> => {
  const storageRef = firebase.storage().ref();
  const urls: FileWithURL[] = [];

  try {
    for (const file of files) {
      validateFile(file);

      let processedFile = file;
      if (file.type === "application/pdf") {
        processedFile = (await optimizePDF(file)) as File;
      } else if (file.type.startsWith("image/")) {
        processedFile = await compressImage(file);
      }

      const timestamp = Date.now();
      const filename = `${timestamp}_${file.name}`;
      const snapshot = await storageRef
        .child(`files/${filename}`)
        .put(processedFile);
      const url = await snapshot.ref.getDownloadURL();
      urls.push({ url, name: filename });
    }

    return urls;
  } catch (error) {
    console.error("Upload error:", (error as Error).message);
    throw error;
  }
};

// Upload files with progress tracking
const uploadImageProgress = async (
  files: File[],
  progressCallback: (progress: number) => void
): Promise<FileWithURL[]> => {
  const storageRef = firebase.storage().ref();
  const urls: FileWithURL[] = [];

  try {
    for (const file of files) {
      validateFile(file);

      let processedFile = file;
      if (file.type === "application/pdf") {
        processedFile = (await optimizePDF(file)) as File;
      } else if (file.type.startsWith("image/")) {
        processedFile = await compressImage(file);
      }

      const timestamp = Date.now();
      const filename = `${timestamp}_${file.name}`;
      const uploadTask = storageRef
        .child(`files/${filename}`)
        .put(processedFile);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          progressCallback(progress);
        },
        (error) => {
          throw error;
        },
        async () => {
          const url = await uploadTask.snapshot.ref.getDownloadURL();
          urls.push({ url, name: filename });
        }
      );
    }

    return urls;
  } catch (error) {
    console.error("Upload error:", (error as Error).message);
    throw error;
  }
};

// Exported functions and storage reference
export const storage = firebase.storage();
export { uploadImage, uploadImageProgress };
