import React from "react";

type Props = {};

export const DownloadIcon = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="black"
      className="w-6 h-6 text-gray-800 dark:text-white"
      viewBox="0 0 24 24"
    >
      <path
        fillRule="evenodd"
        d="M13 11.15V4a1 1 0 10-2 0v7.15L8.78 8.374a1 1 0 10-1.56 1.25l4 5a1 1 0 001.56 0l4-5a1 1 0 10-1.56-1.25L13 11.15z"
        clipRule="evenodd"
      ></path>
      <path
        fillRule="evenodd"
        d="M9.657 15.874L7.358 13H5a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2h-2.358l-2.3 2.874a3 3 0 01-4.685 0zM17 16a1 1 0 100 2h.01a1 1 0 100-2H17z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};
